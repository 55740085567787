import React from 'react';
import { FiveGridLineWrapper } from '../../FiveGridLineWrapper';
import { SkinFiveGridLineProps } from '../SkinFiveGridLine';
import skinsStyle from './styles/skins.scss';

const FiveGridLine: React.FC<SkinFiveGridLineProps> = ({ skin, ...rest }) => {
  return (
    <FiveGridLineWrapper {...rest} className={skinsStyle[skin]}>
      <div className={skinsStyle.lineCnt}>
        <div className={skinsStyle.line}></div>
      </div>
      <div className={skinsStyle.leftKnobCnt}>
        <div className={skinsStyle.leftKnob}></div>
      </div>
      <div className={skinsStyle.middleKnobCnt}>
        <div className={skinsStyle.middleKnob}></div>
      </div>
      <div className={skinsStyle.rightKnobCnt}>
        <div className={skinsStyle.rightKnob}></div>
      </div>
    </FiveGridLineWrapper>
  );
};

export default FiveGridLine;
