import React from 'react';
import { FiveGridLineWrapper } from '../../FiveGridLineWrapper';
import { SkinFiveGridLineProps } from '../SkinFiveGridLine';
import skinsStyle from './styles/skins.scss';

const DoubleFiveGridLine: React.FC<SkinFiveGridLineProps> = ({
  skin,
  ...rest
}) => {
  return (
    <FiveGridLineWrapper {...rest} className={skinsStyle[skin]}>
      <div className={skinsStyle.line}>
        <div className={skinsStyle.top} />
        <div className={skinsStyle.bottom} />
      </div>
      <div className={skinsStyle.tile} />
    </FiveGridLineWrapper>
  );
};

export default DoubleFiveGridLine;
