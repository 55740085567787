import React from 'react';
import { SkinFiveGridLineProps } from '../SkinFiveGridLine';
import { FiveGridLineWrapper } from '../../FiveGridLineWrapper';
import skinsStyle from './styles/skins.scss';

const EmptyLine: React.FC<SkinFiveGridLineProps> = ({
  skin,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  children,
  ...rest
}) => {
  return <FiveGridLineWrapper {...rest} className={skinsStyle[skin]} />;
};

export default EmptyLine;
