import React from 'react';
import classnames from 'classnames';
import { FiveGridLineWrapper } from '../../FiveGridLineWrapper';
import { SkinFiveGridLineProps } from '../SkinFiveGridLine';
import skinsStyle from './styles/skins.scss';

const ZigZagLine: React.FC<SkinFiveGridLineProps> = ({ skin, ...rest }) => {
  return (
    <FiveGridLineWrapper
      {...rest}
      className={classnames(skinsStyle[skin], skinsStyle.root)}
    >
      <div className={skinsStyle.filler} />
      <div className={skinsStyle.zigzag} />
    </FiveGridLineWrapper>
  );
};

export default ZigZagLine;
