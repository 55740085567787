import React from 'react';
import { Identity } from '@wix/editor-elements-types';
import { FiveGridLineProps } from '../FiveGridLine.types';
import { SkinFiveGridLineProps } from './skinComps/SkinFiveGridLine';
import {
  BasicLine,
  DoubleLine,
  FiveGridLine,
  ZigZagLine,
  EmptyLine,
} from './skinComps';

export const SKIN_NAMES: Identity<FiveGridLineProps['skin']> = {
  ArrowLine: 'ArrowLine',
  ArrowRightLine: 'ArrowRightLine',
  FiveGridLineSkin: 'FiveGridLineSkin',
  DashedLine: 'DashedLine',
  DottedLine: 'DottedLine',
  DoubleLine: 'DoubleLine',
  DoubleLine2: 'DoubleLine2',
  DoubleLine3: 'DoubleLine3',
  FadeLine: 'FadeLine',
  FadeNotchBottomLine: 'FadeNotchBottomLine',
  FadeNotchTopLine: 'FadeNotchTopLine',
  FiveGridLine: 'FiveGridLine',
  IronLine: 'IronLine',
  NotchDashedLine: 'NotchDashedLine',
  NotchLine: 'NotchLine',
  ShadowBottomLine: 'ShadowBottomLine',
  ShadowTopLine: 'ShadowTopLine',
  SloppyLine: 'SloppyLine',
  SolidLine: 'SolidLine',
  ZigzagLineFlipSkin: 'ZigzagLineFlipSkin',
  ZigzagLineSkin: 'ZigzagLineSkin',
} as const;

export const FiveGridLineSkinMap: {
  [P in FiveGridLineProps['skin']]: React.FC<SkinFiveGridLineProps>;
} = {
  FiveGridLineSkin: EmptyLine,
  DashedLine: EmptyLine,
  DottedLine: EmptyLine,
  SolidLine: EmptyLine,

  DoubleLine: BasicLine,
  IronLine: BasicLine,
  NotchDashedLine: BasicLine,
  NotchLine: BasicLine,
  SloppyLine: BasicLine,
  FadeLine: BasicLine,
  ArrowLine: BasicLine,
  ArrowRightLine: BasicLine,
  FadeNotchBottomLine: BasicLine,
  FadeNotchTopLine: BasicLine,
  ShadowBottomLine: BasicLine,
  ShadowTopLine: BasicLine,

  DoubleLine2: DoubleLine,
  DoubleLine3: DoubleLine,

  FiveGridLine,

  ZigzagLineFlipSkin: ZigZagLine,
  ZigzagLineSkin: ZigZagLine,
};
