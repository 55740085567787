import * as React from 'react';
import { FiveGridLineProps } from '../FiveGridLine.types';
import { FiveGridLineSkinMap } from './skinMap';

const FiveGridLine: React.FC<FiveGridLineProps> = props => {
  const { skin = 'FiveGridLineSkin' } = props;
  const FiveGridLineClass = FiveGridLineSkinMap[skin];

  return FiveGridLineClass ? (
    <FiveGridLineClass {...props} />
  ) : (
    <div id={props.id} />
  );
};

export default FiveGridLine;
